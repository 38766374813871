<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-toolbar
          color="white"
          flat
      >
        <v-btn
            icon
            light
            link
            to="/accomodations"
        >
          <v-icon color="grey darken-2">
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title class="grey--text text--darken-4">
          Gegevens accomodatie
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()">
          Bewaren
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col md="8" sm="12">
            <v-text-field
                v-model="accomodation.Name"
                label="Naam"
                outlined
                required
            />
          </v-col>
          <v-col md="4" sm="12">
            <v-text-field
                v-model="accomodation.Qty"
                label="Aantal accomodaties"
                outlined
                type="number"
            />
          </v-col>
          <v-col md="3" sm="6">
            <v-text-field
                v-model="accomodation.MaxGuests"
                label="Max aantal gasten"
                outlined
                type="number"

            />
          </v-col>
          <v-col md="3" sm="6">
            <v-text-field
                v-model="accomodation.MaxChildren"
                label="Max aantal kinderen"
                outlined
                type="number"

            />
          </v-col>
          <v-col md="3" sm="6">
            <v-text-field
                v-model="accomodation.ExtraGuestCost"
                label="Extra kost per gast"
                outlined
                type="number"

            />
          </v-col><v-col md="3" sm="6">
            <v-text-field
                v-model="accomodation.ExtraChildCost"
                label="Extra kost per kind"
                outlined
                type="number"

            />
          </v-col>

          <v-col md="12" sm="12">
            <v-textarea
                :value="accomodation.Description"
                label="Omschrijving"
                outlined
            />
          </v-col>
          <v-col md="12" sm="12" class="">
            <vue-editor :placeholder="'Email info'" v-model="accomodation.EmailDescription"></vue-editor>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Accomodation} from "@/models/accomodation";
import {AccomodationService} from "@/services/accomodation-service";
import router from "@/router";

export default {
  data() {
    return {
      accomodation: new Accomodation()
    }
  },
  methods: {
    async save() {
      if (this.accomodation.ID) {
        await AccomodationService.update(this.accomodation)
      } else {
        await AccomodationService.insert(this.accomodation)
        await router.push('/accomodations');
      }
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.accomodation = await AccomodationService.single(this.$route.params.id)
    }
  }
}
</script>